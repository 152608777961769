import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopHouse,
  faBell,
  faUserCircle,
  faSignOutAlt,
  faMapMarkerAlt,
  faUser,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { TextAtom, TextColors, TextVariants } from "../../atoms";
import "./NavMenu.scss";
import { useHistory } from "react-router";
import { ROUTES } from "../../../utils/constants";
import classnames from "classnames";
import metadata from "../../../../package.json";
import { notify } from "../../../utils";

const menuItems = [
  { name: "Home", route: ROUTES.mapWithCurrentLocation, icon: faLaptopHouse},
  // { name: "My Group", route: ROUTES.myGroup, icon: faUserCircle },
  { name: "Profile", route: ROUTES.profile, icon: faUser },
  { name: "Notifications", route: ROUTES.notifications, icon: faBell },
  // { name: "Sites", route: ROUTES.sites, icon: faMapMarkerAlt },
  // { name: "Account", route: ROUTES.account, icon: faUserCircle },
  // { name: "Devices", route: ROUTES.devices, icon: faTablet },
];

function NavMenu(props) {
  const { className, onMenuItemClicked, ...rest } = props;
  const history = useHistory();

  const handleMenuItemClicked = (item, route, disabled) => {
    if (disabled) {
      notify.info("This page is not yet made.");
      return;
    }
    menuItems.map((item) => (
      item.active = false
    ));
    history.push(route);
    onMenuItemClicked && onMenuItemClicked();
  };

  const handleSignOutClicked = () => {
    history.push(ROUTES.signIn);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  };

  const makeActive = (route) => {
    const item = menuItems.find((item) => item.route === route)
  //  item.active = true
  }

  const renderMenuItems = () => {
    makeActive(history.location.pathname)
    const pages = menuItems.map((item) => (
      item.active === true
       ?(<TextAtom
        disabled={item.disabled}
        key={item.name}
        variant={TextVariants.regular}
        className="nav-menu__molecule-item-is-active"
        onClick={() => handleMenuItemClicked(item, item.route, item.disabled)}>
        <FontAwesomeIcon icon={item.icon} className="icon" />
        {item.name}
      </TextAtom>
      )
     : (<TextAtom
      disabled={item.disabled}
      key={item.name}
      variant={TextVariants.regular}
      className="nav-menu__molecule-itemr"
      onClick={() => handleMenuItemClicked(item, item.route, item.disabled)}>
      <FontAwesomeIcon icon={item.icon} className="icon" />
      {item.name}
    </TextAtom>
     )
    ));

    const signOutButton = (
      <TextAtom
        key="SignOut"
        variant={TextVariants.regular}
        className="nav-menu__molecule-item-signout"
        onClick={handleSignOutClicked}
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
        Sign Out
      </TextAtom>
    );

    const menu = [...pages, signOutButton];

    return menu;
  };
  const classes = classnames("nav-menu__molecule", className);
  return (
    <div className={classes} {...rest}>
      {renderMenuItems()}
      <TextAtom
        className="side-bar__organism-app-version"
        variant={TextVariants.small}
        color={TextColors.primaryGrey}
      >
        {metadata.version}
      </TextAtom>
    </div>
  );
}

export default NavMenu;
