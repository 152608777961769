import { makeRequest, StateActions } from "../../utils";
import { API_ENDPOINTS } from "../../utils/constants";
import { ADD_MEMBERS } from "./types";


export const getMembers = () => async (dispatch) => {
    const action = new StateActions(ADD_MEMBERS);
    dispatch(action.loading);
    try {
        // const response = await makeRequest.get(API_ENDPOINTS.members);

        const response = await makeRequest.get(API_ENDPOINTS.members,null, null, {
            limit: 20
          } );
        if (response.success === false) dispatch(action.error(response));
        if (response.success === true) dispatch(action.loaded(response));
    } catch (error) {
        console.error(error);
        dispatch(action.error(error));
    }
};
