import React, { useEffect, useState, useCallback, useRef } from "react";
import { Error, Loading, Page, TextAtom } from "../../../atoms";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../../utils/constants"; //../../../../../utils/constants
import ProfileIcon from "../../../../assets/images/default-profile-icon.png";
import { InteriorPageHeadingWithCTA } from "../../../molecules";
import { ProfileHeaderSection, ProfileRelatedDisplay } from "../../../organisms";
import { Interior } from "../../../templates";
import { REQ_STATE } from "../../../../utils";
import "./ProfileDetailsComponent.scss";

import { PostCard } from "../../../molecules";
import { useFetch } from "../../../../utils/hooks";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { notify } from "../../../../utils";


// const RenderFeedData = (props) => {
//   const { feed, feedItems, ...rest } = props;

//   if(feed.state !== "loaded")
//     return null

//   const posts = feedItems;

//   const postCards = () => {
//       if (posts?.length !== 0){
//         return posts.map((post) => (
//           <PostCard  post={post} />
//         ));
//       }else return;
//   };
//   return (
//     <>
//       {postCards()}
//     </>
//   );
// };

function ProfileDetailsComponent(props) {
  const { sites, user, feed } = props;
  const [feedItems, setFeedItems] = useState(feed?.data?.results || []);

  useEffect(() => {
    setFeedItems(feed?.data?.results || [])
  }, [feed]);

  const handleUserData = () => {
    // eslint-disable-next-line default-case
    switch (user.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return <ProfileHeaderSection user={user} />;
    }
  };

  const handleUserFeedData = () => {
    const posts = feedItems;

    const postCards = () => {
      if (posts?.length !== 0){
        return posts.map((post) => (
          // <div className="post">{post.body}</div>
          <PostCard className={"post-card__molecule-profile"} post={post} />
        ));
      }else return;
    };
    // eslint-disable-next-line default-case
    switch (feed.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return (<>{postCards()}</>);
    }
  };
 
  return (
   <div className="profile-components">
        {handleUserData()}
        {handleUserFeedData()}
    </div>
  );
}

export default ProfileDetailsComponent;
