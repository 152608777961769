import React from "react";
import { useSelector } from "react-redux";
import ProfileDetailsComponent from "./component";

function ProfileDetails() {
  const feed = useSelector((state) => state.feed);
  const sites = useSelector((state) => state.sites);
  const user = useSelector((state) => state.user);
  return <ProfileDetailsComponent feed={feed} user={user} sites={sites} />;
}

export default ProfileDetails;
