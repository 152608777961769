import { makeRequest, StateActions } from "../../utils";
import { ADD_FEED } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getFeed = () => async (dispatch) => {
  // Create an object of the class State Actions

  const action = new StateActions(ADD_FEED);
  dispatch(action.loading);
  try {

    const response = await makeRequest.get(API_ENDPOINTS.feed,null, null, {
      limit: 10
    } );

    if (response.success === false){
      // console.log("feed error: ", response)
      dispatch(action.error(response));
    }
    if (response.success === true){
      // console.log("feed success: ", response)
      dispatch(action.loaded(response));
    } 
  } catch (error) {
    // console.log("feed success: ", error)
    console.error(error);
    dispatch(action.error(error));
  }
};
